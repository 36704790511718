import { FunctionComponent } from "react";
import "./Navigation.scss";

export interface NavigationProps {
    isFirstStep: boolean;
    isLastStep: boolean;
    onPreviousStep: () => void;
    onNextStep: () => void;
}

export const Navigation: FunctionComponent<NavigationProps> = (props) => {
    const previousStep = () => {
        // TODO: analytics
        props.onPreviousStep();
    };
    const nextStep = () => {
        // TODO: analytics
        props.onNextStep();
    };

    return (
        <div className="rb-navigation-component">
            <div className="row">
                <div className="col">
                    {!props.isFirstStep ? (
                        <button
                            type="button"
                            className="btn btn-outline-secondary py-2 px-3"
                            onClick={() => previousStep()}
                            title="Back"
                        >
                            Back
                        </button>
                    ) : null}
                </div>
                <div className="col text-end">
                    {!props.isLastStep ? (
                        <button
                            type="button"
                            className="btn btn-primary text-white py-2 px-3"
                            onClick={() => nextStep()}
                            title="Save &amp; Continue"
                        >
                            Save &amp; Continue
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default Navigation;
