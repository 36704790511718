import OrderType from "./OrderType";
import Product from "./Product";

export class Order {
    id: string;
    orderType: OrderType;
    orderNumber: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    paymentProvider: "None" | "PayPal";
    paymentId: string;
    paymentAmount: number;
    product: Product

    constructor();
    constructor(order: Partial<Order>)
    constructor(data?: Partial<Order>) {
        data = data || {};

        this.id = data.id || null;
        this.orderType = data.orderType || 0;
        this.orderNumber = data.orderNumber || null;
        this.firstName = data.firstName || null;
        this.lastName = data.lastName || null;
        this.emailAddress = data.emailAddress || null;
        this.phoneNumber = data.phoneNumber || null;
        this.paymentProvider = data.paymentProvider || null;
        this.paymentId = data.paymentId || null;
        this.paymentAmount = data.paymentAmount || 0;
        this.product = data.product ? new Product(data.product) : null;
    }
};

export default Order;
