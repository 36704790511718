import { FunctionComponent, SyntheticEvent } from "react";
import "./GettingStarted.scss";

export interface GettingStartedProps {
    onATSClicked: (e: SyntheticEvent, url: string) => void;
    onResumeGuideClicked: (e: SyntheticEvent, url: string) => void;
    onHireAnExpertClicked: (e: SyntheticEvent, url: string) => void;
}

export const GettingStarted: FunctionComponent<GettingStartedProps> = (props) => {
    return (
        <div className="wl-getting-started-component">
            <div className="container-fluid mt-3 text-center">
                <h2 className="h3">
                    Building Your Best Resume
                </h2>
                <div className="row my-4">
                    <div className="col-12 col-md-2 col-lg-3 col-xl-2 offset-xl-1 text-md-end">
                        <span className="step-number">
                            <span className="">
                                1
                            </span>
                        </span>
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 ps-md-5 text-start">
                        <h4 className="text-center text-md-start mt-2 mt-md-0">
                            Understand ATS
                        </h4>
                        <p>
                            Applicant Tracking Systems (ATS) are the first obstacle to overcome for most applicants.
                            These systems match keywords within a resume against those of the job listing and automatically filter out candidates.
                        </p>
                        <p>
                            For a better understanding of ATS and how to tailor your resume for ATS parsing please read our <a
                                href="/understanding-ats"
                                title="Understanding ATS"
                                onClick={e => props.onATSClicked(e, "/understanding-ats")}
                            >
                                Understanding ATS
                            </a> page for a deeper insight into ATS.
                        </p>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-12 col-md-2 col-lg-3 col-xl-2 offset-xl-1 text-md-end">
                        <span className="step-number">
                            <span className="">
                                2
                            </span>
                        </span>
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 ps-md-5 text-start">
                        <h4 className="text-center text-md-start mt-2 mt-md-0">
                            Follow Our Resume Guide
                        </h4>
                        <p>
                            Our experts have written an easy-to-use guide on how to write a resume.
                            This guide goes over some of the do's and don'ts of resume writing and includes examples to help guide you as you write your resume.
                        </p>
                        <p>
                            Our guide on <a
                                href="/how-to-write-a-resume"
                                title="How to Write a Resume"
                                onClick={e => props.onResumeGuideClicked(e, "/how-to-write-a-resume")}
                            >
                                How to Write a Resume
                            </a> will help you build a resume that is easy for both ATS and a hiring agent to read.
                        </p>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-12 col-md-2 col-lg-3 col-xl-2 offset-xl-1 text-md-end">
                        <span className="step-number">
                            <span className="">
                                3
                            </span>
                        </span>
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 ps-md-5 text-start">
                        <h4 className="text-center text-md-start mt-2 mt-md-0">
                            Get Assistance From An Expert
                        </h4>
                        <p>
                            If you are still having doubts about your resume or would like someone else to do it, we are here to help!
                            Our resume experts will revise your existing resume or create an entirely new resume for you.
                        </p>
                        <p>
                            Our resume experts are CPRW certified by The Professional Association of Résumé Writers and have spent the last 5+ years
                            specializing in resume writing and recruiting.
                        </p>
                        <p className="text-center text-md-start">
                            <a
                                href="/professional-resume-writing-services"
                                className="btn btn-lg btn-primary text-white"
                                title="Hire an Expert"
                                onClick={e => props.onHireAnExpertClicked(e, "/professional-resume-writing-services")}
                            >
                                Hire an Expert
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GettingStarted;
