import { FunctionComponent } from "react";
import "./Privacy.scss";

export interface PrivacyProps { }

export const Privacy: FunctionComponent<PrivacyProps> = (props) => {
    return (
        <div className="pv-privacy-component container mt-3">
            <h1>Resume Butlers LLC Privacy Policy</h1>
            <p className="mb-5 small">
                Updated October 2021
            </p>

            <h2 className="h3">Data Collection</h2>
            <p>
                Resume Butlers collects data from you when you use our websites and applications.
                This data may be data that is provided by you directly or may be data collected automatically as you interact and use our products.
            </p>

            <h2 className="h3">Data Use</h2>
            <p>
                Resume Butlers use the data in our products as well as to operate the business.
                Data may be used for analytical purposes, for research, or for any other legitimate purpose.
                We do not sell your data; however, we may use third parties to aggregate or collect the data.
            </p>

            <h2 className="h3">Data Sharing</h2>
            <p>
                Resume Butlers used third party software to collect some data.
                We may also share data we’ve collected with third parties when using tools to collect, aggregate, or report on the collected data.
                We will share data when requested for legal process or required by law.
            </p>

            <h2 className="h3">Cookies and Advertising</h2>
            <p>
                Resume Butlers may use cookies or similar technologies to store preferences, identifiers, advertising trackers, or other information on your device.
                We may display advertisements and use advertising tools.
                These third-party advertisers may use cookies or similar technologies as well as a variety of tracking techniques.
            </p>

            <h2 className="h3">Managing My Data</h2>
            <p>
                Resume Butlers may provide tools to manage specific data, especially data you provide.
                Requests to delete data that cannot be managed by you can be sent by email to <a href="mailto:privacy@resumebutlers.com" title="privacy@resumebutlers.com" target="_blank" rel="nofollow">privacy@resumebutlers.com</a>.
            </p>
        </div>
    );
}

export default Privacy;
