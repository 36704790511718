import { faTrophyAlt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { orderApi, productApi } from "../../apis";
import { Order, OrderType, Product } from "../../models";
import { analytics, storageUtility } from "../../utilities";
import "./Checkout.scss";
import ContactInfo from "./ContactInfo/ContactInfo";
import OrderLineItems from "./OrderLineItems/OrderLineItems";
import "./PayPalCheckout";
import { initPayPal } from "./PayPalCheckout";

enum CheckoutStep {
    ContactInfo = 10,
    Checkout = 20,
    Complete = 30,
}

type CheckoutInfo = Order & {
    headerText: string;
    orderType: OrderType;
}

export interface CheckoutProps { }

export const Checkout: FunctionComponent<CheckoutProps> = (props) => {
    const storageKey = "checkout-current-order";
    const checkoutInfo = useMemo(() => storageUtility.getFromLocalStorage<CheckoutInfo>(storageKey) || {} as Partial<CheckoutInfo>, []);
    const [products, setProducts] = useState<Product[]>([]);
    const [order, setOrder] = useState<Order>(new Order(checkoutInfo || { orderType: checkoutInfo.orderType, }));
    const [product, setProduct] = useState<Product>();
    const [step, setStep] = useState<CheckoutStep>(getStep(order, product));
    const print = () => window.print();
    const finalizeOrder = (order: Partial<Order>, paymentProvider: "None" | "PayPal", paymentId: string, amount: number) => orderApi.getNextOrderNumber(order.orderType)
        .then(orderNumber => {
            const newOrder = new Order(order);
            newOrder.orderNumber = orderNumber;
            newOrder.paymentProvider = paymentProvider || "None";
            newOrder.paymentId = paymentId;
            newOrder.paymentAmount = amount || 0;

            setOrder(newOrder);
            setStep(CheckoutStep.Complete);

            analytics.trackEvent("Checkout Payment Submitted", {
                orderNumber: newOrder.orderNumber,
                orderType: newOrder.orderType,
                paymentAmount: newOrder.paymentAmount,
            });

            return newOrder;
        })
        .then(o => orderApi.saveOrder(o))
        .catch(err => { /* TODO: error handling */});

    useEffect(() => {
        if (!product) return;

        if (step === CheckoutStep.Checkout) {
            new Promise<void>((resolve) => {
                const handle = setInterval(() => {
                    const buttons = document.querySelector("#paypal-button-container");
                    if (buttons) {
                        clearInterval(handle);
                        resolve();
                    }
                }, 100);
            }).then(() => initPayPal(product).then((o) => finalizeOrder(order, "PayPal", o.id, o.purchase_units?.map(pu => Number(pu?.amount?.value) || 0).reduce((a, b) => a + b))))
        } else if (step === CheckoutStep.Complete) {
            storageUtility.saveToLocalStorage(storageKey, null);
        }
    }, [step, product]);

    useEffect(() => {
        productApi.getProducts()
            .then(p => setProducts(p))
            .catch(err => { /* TODO: error handling */});
    }, []);

    useEffect(() => {
        const product = products?.find(p => p.orderType === order.orderType);

        if (product) {
            setProduct(product);

            if (!order?.product) {
                order.product = product;
            }
        }
    }, [products, order]);

    return product ? (
        <div className="ck-checkout-component">
            <div className="container">
                <h1 className="h2 text-center">
                    { checkoutInfo.headerText }
                </h1>
                <div className="d-lg-none">
                    <div className="row">
                        <div className="col-12 col-sm-6 text-center text-sm-start">
                            <h2 className="h6">{ product.name }</h2>
                        </div>
                        <div className="col text-center text-sm-end fw-bold">
                            ${ (product.price || 0).toFixed(2) }
                        </div>
                    </div>
                    <hr className={!product.name ? "d-none" : "d-lg-none"} />
                </div>
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <div className="mt-2 d-none d-lg-block">
                            <h2 className="h4 text-center">
                                Order Details
                            </h2>
                            <OrderLineItems
                                lineItems={product?.lineItems || []}
                            />
                            <hr />
                            <div className="row">
                                <div className="col-12 col-sm-9 h5 text-center text-sm-start">
                                    Total
                                </div>
                                <div className="col-12 col-sm-3 text-center h5 text-sm-end mt-2 mt-sm-0">
                                    ${ (product.price || 0).toFixed(2) }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 offset-lg-1 mt-2">
                        {step === CheckoutStep.ContactInfo ? (
                            <div>
                                <h2 className="h4 text-center">
                                    Contact Information
                                </h2>
                                <ContactInfo
                                    smallContainer={true}
                                    onSubmit={o => {
                                        const newOrder = new Order({ ...order, ...o, });
                                        const step = getStep(newOrder, product);
                                        setOrder(newOrder);
                                        setStep(getStep(newOrder, product));
                                        analytics.trackEvent("Checkout Save Contact Info Clicked");

                                        if (step === CheckoutStep.Complete) {
                                            finalizeOrder(newOrder, "None", null, 0);
                                        }
                                    }}
                                />
                            </div>
                        ) : null}
                        {step === CheckoutStep.Checkout ? (
                            <div>
                                <h2 className="h4 text-center">
                                    Payment
                                </h2>
                                <div id="smart-button-container">
                                    <div className="text-center">
                                        <div id="paypal-button-container"></div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {step === CheckoutStep.Complete ? (
                            <div className="mt-3">
                                <h2 className="h5 w-100 text-center">
                                    Order {order.orderNumber} Confirmed
                                </h2>
                                <div className="row mt-3">
                                    <div className="col-2 d-none d-md-block text-end text-primary pe-3">
                                        <FontAwesomeIcon
                                            icon={faTrophyAlt}
                                            title="Purchase Complete"
                                            size="5x"
                                        />
                                    </div>
                                    <div className="col">
                                        <p>
                                            Thank you for your order! You should receive an email receipt within the next few minutes.
                                        </p>
                                        <p>
                                            If you have any questions, concerns, or would like to cancel your order please feel free to reach out to us at customer-service@resumebutlers.com
                                        </p>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary text-white btn-sm d-print-none"
                                        onClick={() => print()}
                                        title="Print Receipt"
                                    >
                                        Print Receipt
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="container pt-5 d-lg-none">
                        <h2 className="h4 text-center">
                            Order Details
                        </h2>
                        <OrderLineItems
                            lineItems={product?.lineItems || []}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="container mt-3">
            <h2 className="h3 text-center">
                Your cart is empty.
            </h2>
            <p className="mt-3 text-center">
                It looks like your cart is empty.
            </p>
        </div>
    );
}

const getStep = (order?: Partial<Order>, product?: Product): CheckoutStep => {
    let step: CheckoutStep = CheckoutStep.ContactInfo;

    if (order) {
        if (order.paymentId) {
            step = CheckoutStep.Complete;
        } else if (order.firstName && order.lastName && order.emailAddress) {
            if (product?.price == 0) {
                step = CheckoutStep.Complete;
            } else {
                step = CheckoutStep.Checkout;
            }
        }
    }

    return step;
}

export default Checkout;
