import { FunctionComponent, ReactElement, useState } from "react";
import "./Builder.scss";
import ContactInfo from "./ContactInfo/ContactInfo";
import Education from "./Education/Education";
import Experience from "./Experience/Experience";
import GetStarted from "./GetStarted/GetStarted";
import Navigation from "./Navigation/Navigation";
import Progress from "./Navigation/Progress";
import Review from "./Review/Review";
import Skills from "./Skills/Skills";

export interface BuilderProps { }

const getStep = (step: number): ReactElement => {
    let element: ReactElement = (<GetStarted />)

    if (step === 10) {
        element = (<ContactInfo />);
    } else if (step === 20) {
        element = (<Experience />);
    } else if (step === 30) {
        element = (<Education />);
    } else if (step === 40) {
        element = (<Skills />);
    } else if (step === 50) {
        element = (<Review />);
    }

    return element;
}

const Builder: FunctionComponent<BuilderProps> = (props) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [maxStep, setMaxStep] = useState<number>(0);
    const stepElement = getStep(currentStep);
    const previousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 10);
        }
    };
    const nextStep = () => {
        // TODO: validate
        setCurrentStep(currentStep + 10);
        setMaxStep(currentStep + 10);
        console.log(maxStep);
    }

    return (
        <div className="rb-builder-component mt-3">
            <div className="container px-0 px-lg-3">
                <div className="row">
                    <div className="col-12 col-lg-4 mb-3 px-0 px-lg-3">
                        <Progress
                            getStartedCompleted={maxStep > 0}
                            contactInfoCompleted={maxStep > 10}
                            experienceCompleted={maxStep > 20}
                            educationCompleted={maxStep > 30}
                            skillsCompleted={maxStep > 40}
                            reviewCompleted={maxStep > 50}
                        />
                    </div>
                    <div className="col">
                        <div className="d-md-none mb-3">
                            <Navigation
                                isFirstStep={currentStep <= 0}
                                isLastStep={currentStep >= 50}
                                onPreviousStep={previousStep}
                                onNextStep={nextStep}
                            />
                        </div>
                        <div className="builder-step">
                            {stepElement}
                        </div>
                        <div className="mt-3">
                            <Navigation
                                isFirstStep={currentStep <= 0}
                                isLastStep={currentStep >= 50}
                                onPreviousStep={previousStep}
                                onNextStep={nextStep}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Builder;
