import { FunctionComponent } from "react";
import "./Review.scss";

export interface ReviewProps { }

export const Review: FunctionComponent<ReviewProps> = (props) => {
    return (
        <div className="rb-review-component">
            Review
        </div>
    );
}

export default Review;
