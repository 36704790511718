import { FunctionComponent } from "react";
import "./Skills.scss";

export interface SkillsProps { };

export const Skills: FunctionComponent<SkillsProps> = (props) => {
    return (
        <div className="rb-skills-component">
            Skills
        </div>
    );
}

export default Skills;
