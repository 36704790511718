import { FunctionComponent } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import config from "../../../config";
import { useNavigate } from "../../../utilities";
import "./Navigation.scss";

export interface NavigationProps {
    hideLinks: boolean;
    useDarkTheme: boolean;
}

/** Primary navigation menu. */
export const Navigation: FunctionComponent<NavigationProps> = (props) => {
    const isProd = config.isProdEnvironment;
    const textColor = props.useDarkTheme ? "text-white" : "text-black";
    const buttonColor = props.useDarkTheme ? "btn-secondary" : "btn-primary text-white";
    const navigate = useNavigate("Navigation Menu");

    return (
        <div className="lo-navigation-component">
            <Navbar
                bg="light"
                expand="md"
                className="py-0"
            >
                <div className="container-fluid">
                    <Navbar.Brand
                        className="text-center text-md-left mx-auto mx-sm-0 ms-2"
                        href="/"
                        onClick={e => navigate(e, "/", "Home Clicked")}
                    >
                        <img src="/images/brand-icon.png" title="Resume Butlers" className="d-inline-block align-top mr-2" />
                        <div className={`${textColor} d-inline-block h-100 align-middle fs-2 ms-2 ms-sm-3`}>
                            Resume Butlers
                        </div>
                    </Navbar.Brand>
                    {!props.hideLinks ? (
                        <Navbar.Toggle
                            aria-controls="lo-nav-dropdown"
                            className="me-2"
                        />
                    ) : null}
                    {!props.hideLinks ? (
                        <Navbar.Collapse id="lo-nav-dropdown" className="pb-1 pb-md-0">
                        <Nav className="ms-1 me-auto my-2">
                        </Nav>
                            <NavDropdown
                                id="nv-cover-letter-dropdown"
                                title="Cover Letters"
                            >
                                {/* <NavDropdown.Item
                                    href="/how-to-write-a-cover-letter"
                                    title="How to Write A Cover Letter"
                                    onClick={e => navigate(e, "/how-to-write-a-cover-letter", "How To Write A Cover Letter Clicked")}
                                >
                                    How to Write A Cover Letter (Guide)
                                </NavDropdown.Item> */}
                                { /* TODO: Free Cover Letter Templates */ }
                                {/* <NavDropdown.Divider /> */}
                                <NavDropdown.Item
                                    href="/free-cover-letter-consultation"
                                    title="Free Cover Letter Consultation"
                                    onClick={e => navigate(e, "/free-cover-letter-consultation", "Free Cover Letter Consultation Clicked")}
                                >
                                    Free Cover Letter Consultation
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href="/professional-cover-letter-consultation"
                                    title="Professional Cover Letter Review"
                                    onClick={e => navigate(e, "/professional-cover-letter-consultation", "Professional Cover Letter Review Clicked")}
                                >
                                    Professional Cover Letter Review
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href="/professional-cover-letter-writing-services"
                                    title="Professional Cover Letter Writing"
                                    onClick={e => navigate(e, "/professional-cover-letter-writing-services", "Professional Cover Letter Writing Clicked")}
                                >
                                    Professional Cover Letter Writing
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                id="nv-resumes-dropdown"
                                title="Resumes"
                            >
                                <NavDropdown.Item
                                    href="/understanding-ats"
                                    title="Understanding ATS"
                                    onClick={e => navigate(e, "/understanding-ats", "Understanding ATS Clicked")}
                                >
                                    Understanding ATS
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href="/how-to-write-a-resume"
                                    title="How to Write A Resume"
                                    onClick={e => navigate(e, "/how-to-write-a-resume", "How To Write A Resume Clicked")}
                                >
                                    How to Write A Resume (Guide)
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    href="/free-resume-consultation"
                                    title="Free Resume Consultation"
                                    onClick={e => navigate(e, "/free-resume-consultation", "Free Resume Consultation Clicked")}
                                >
                                    Free Resume Consultation
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href="/professional-resume-consultation"
                                    title="Professional Resume Review"
                                    onClick={e => navigate(e, "/professional-resume-consultation", "Professional Resume Review Clicked")}
                                >
                                    Professional Resume Review
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href="/professional-resume-writing-services"
                                    title="Professional Resume Writing"
                                    onClick={e => navigate(e, "/professional-resume-writing-services", "Professional Resume Writing Clicked")}
                                >
                                    Professional Resume Writing
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav>
                                <Nav.Link
                                    className={`btn ${buttonColor} ${isProd ? "d-none d-md-block invisible" : null}`}
                                    href={!isProd ? "/resume-builder" : "#"}
                                    onClick={e => navigate(e, "/resume-builder", "Resume Builder Clicked")}
                                >
                                    Get Started
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    ) : null}
                </div>
            </Navbar>
        </div>
    );
}

export default Navigation;
