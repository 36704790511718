import { SyntheticEvent, useState } from "react";
import { Order, OrderType } from "../../models";
import { storageUtility, useNavigate } from "../../utilities";

export const useCheckout = (module: string, orderType: OrderType, checkoutHeader: string, order?: Partial<Order>): [order: Order, setOrder: (order: Order) => void, checkout: (order: Order) => void] => {
    const [o, setO] = useState<Order>(new Order(order || {orderType}));
    const navigate = useNavigate(module);
    const setOrder = (order: Order) => {
        const orderData = {
            ...order,
            headerText: checkoutHeader,
            orderType,
        };
        
        storageUtility.saveToLocalStorage("checkout-current-order", orderData);
        setO(order);
    };
    const event = { preventDefault: () => {}, } as SyntheticEvent<HTMLElement>;
    const checkout = (order: Order) => {
        setOrder(order);
        navigate(event, "/checkout");
    };

    return [o, setOrder, checkout];
};

export default useCheckout;
