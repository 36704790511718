/** A line item for an offered Product. */
export class ProductLineItem {
    name: string;
    description: string;
    price: number;

    constructor();
    constructor(productLineItem: Partial<ProductLineItem>);
    constructor(data?: Partial<ProductLineItem>) {
        data = data || {};

        this.name = data.name || null;
        this.description = data.description || null;
        this.price = data.price || 0;
    }
};

export default ProductLineItem;
