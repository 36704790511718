import OrderType from "./OrderType";
import ProductLineItem from "./ProductLineItem";

/** A product offered by ResumeButlers.com */
export class Product {
  id: number;
  orderType: OrderType;
  name: string;
  description: string;
  price: number;
  lineItems: ProductLineItem[];

  constructor();
  constructor(product: Partial<Product>);
  constructor(data?: Partial<Product>) {
    data = data || {};

    this.id = data.id || data.orderType || 0;
    this.orderType = data.orderType || 0;
    this.name = data.name || null;
    this.description = data.description || null;
    this.price = data.price || 0;
    this.lineItems = (data.lineItems || []).map(lineItem => new ProductLineItem(lineItem));
  }
};

export default Product;
