import { FormEvent, FunctionComponent, useState } from "react";
import * as yup from "yup";
import { Order } from "../../../models";
import { FormControl } from "../../Form";
import "./ContactInfo.scss";

type Contact = Pick<Order, "firstName" | "lastName" | "emailAddress" | "phoneNumber">;

export interface ContactProps {
    smallContainer?: boolean;
    onSubmit: (contactInfo: Contact) => void;
};

export const ContactInfo: FunctionComponent<ContactProps> = (props) => {
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [emailAddress, setEmailAddress] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const contact: Contact = {
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
        };

        props.onSubmit(contact);
    }
    
    return (
        <div className="ck-contact-info">
            <form onSubmit={e => onFormSubmit(e)}>
                <div className="row">
                    <div className={`col-12 ${!props.smallContainer ? "col-md-6" : null} mt-3`}>
                        <FormControl
                            name="First Name"
                            placeHolder="e.g. Jamie"
                            autocomplete="given-name"
                            maxLength={200}
                            validation={validation.fields.firstName}
                            onChange={v => setFirstName(v ? String(v) : null)}
                        />
                    </div>
                    <div className={`col-12 ${!props.smallContainer ? "col-md-6" : null} mt-3`}>
                        <FormControl
                            name="Last Name"
                            placeHolder="e.g. Wright"
                            autocomplete="family-name"
                            maxLength={200}
                            validation={validation.fields.lastName}
                            onChange={v => setLastName(v ? String(v) : null)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className={`col-12 ${!props.smallContainer ? "col-md-6" : null} mt-3`}>
                        <FormControl
                            name="Email Address"
                            placeHolder="e.g. jwright@email.net"
                            type="email"
                            autocomplete="email"
                            maxLength={300}
                            validation={validation.fields.emailAddress}
                            onChange={v => setEmailAddress(v ? String(v) : null)}
                        />
                    </div>
                    <div className={`col-12 ${!props.smallContainer ? "col-md-6" : null} mt-3`}>
                        <FormControl
                            name="Phone Number"
                            placeHolder="e.g. 888-888-8888"
                            type="tel"
                            autocomplete="tel"
                            maxLength={30}
                            validation={validation.fields.phoneNumber}
                            onChange={v => setPhoneNumber(v ? String(v) : null)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-end">
                        <div className="d-grid d-md-block gap-2">
                            <button
                                type="submit"
                                className="btn btn-lg btn-primary mt-3 d-block d-md-inline-block text-white"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

const validation = yup.object({
    firstName: yup
        .string()
        .required("First name is required.")
        .typeError("Please provide valid first name."),
    lastName: yup
        .string()
        .required("Last name is required.")
        .typeError("Please provide valid last name."),
    emailAddress: yup
        .string()
        .email("Please provide a valid email address.")
        .required("Email address is required.")
        .typeError("Please provide a valid email address."),
    phoneNumber: yup
        .string()
        .optional()
        .nullable(),
});

export default ContactInfo;
