import { FunctionComponent } from "react";
import { Order, OrderType } from "../../models";
import { analytics } from "../../utilities";
import { ContactInfo, useCheckout } from "../Checkout";
import "./HireAnExpert.scss";
import {
    AdvancedCoverLetterReview,
    AdvancedResumeReview,
    BasicCoverLetterReview,
    BasicResumeReview,
    CheckoutPage,
    ProfessionalCoverLetterWriting,
    ProfessionalResumeWriting
} from "./Steps";

type Pages = {
    [key in OrderType]: CheckoutPage;
}

const pages: Pages = {
    [OrderType.HireAnExpert]: ProfessionalResumeWriting,
    [OrderType.ProfessionalResumeWriting]: ProfessionalResumeWriting,
    [OrderType.BasicResumeReview]: BasicResumeReview,
    [OrderType.AdvancedResumeReview]: AdvancedResumeReview,
    [OrderType.ProfessionalCoverLetterWriting]: ProfessionalCoverLetterWriting,
    [OrderType.BasicCoverLetterReview]: BasicCoverLetterReview,
    [OrderType.AdvancedCoverLetterReview]: AdvancedCoverLetterReview,
};

export interface HireAnExpertProps {
    orderType: OrderType;
}

export const HireAnExpert: FunctionComponent<HireAnExpertProps> = (props) => {
    const [order, setOrder, checkout] = useCheckout("Hire An Expert", props.orderType, "Hire An Expert");
    const page = pages[props.orderType];
    const onSubmit = (contactInfo: Partial<Order>) => {
        const newOrder = { ...order, ...contactInfo, };
        checkout(newOrder);
    };
    const isFree = props.orderType == OrderType.BasicResumeReview || props.orderType == OrderType.BasicCoverLetterReview;

    return page ? (
        <div className="he-hire-an-expert-component">
            <div>
                <section className="container-fluid p-0">
                    <div className="hero">
                        <div className="hero-text py-3">
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 offset-lg-6 offset-lg-7 bg-white-trans text-center">
                                    <h1 className="h1">
                                        { page.title }
                                    </h1>
                                    <h2 className="h4">
                                        { page.subtitle}
                                    </h2>
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-primary my-3 text-white"
                                        title={isFree ? "Request Now" : "Buy Now"}
                                        onClick={() => {
                                            analytics.trackEvent("Hire An Expert: Buy Now Clicked");
                                            onSubmit({});
                                        }}
                                    >
                                        {isFree ? "Request Now" : "Buy Now"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container mt-3 text-center">
                    <h2 className="h3">
                        { page.checkoutTitle}
                    </h2>
                    <h3 className="h5 mt-3 mb-5 d-none d-md-block">
                        Get started in a few quick steps.
                    </h3>
                    {page.steps.map((s, idx) => (
                        <div key={idx} className="row my-4">
                            <div className="col-12 col-md-3 col-xl-2 text-md-end">
                                <span className="step-number">
                                    <span className="">
                                        {idx + 1}
                                    </span>
                                </span>
                            </div>
                            <div className="col-12 col-md-9 col-xl-9 ps-md-5 text-start">
                                <h4 className="text-center text-md-start mt-2 mt-md-0">
                                    {s.title}
                                </h4>
                                <p>
                                    {s.text}
                                </p>
                            </div>
                        </div>
                    ))}
                </section>
                <section className="w-100 mt-3 p-3 pb-5 bg-light">
                    <div className="container">
                        <ContactInfo
                            onSubmit={onSubmit}
                        />
                    </div>
                </section>
            </div>
        </div>
    ): (
        <p className="mt-3 text-center">
            Your cart is empty.
        </p>
    )
};

export default HireAnExpert;
