import { FunctionComponent, SyntheticEvent } from "react";
import { useNavigate } from "../../utilities";
import GettingStarted from "./GettingStarted/GettingStarted";
import Hero from "./Hero/Hero";
import InfoCards from "./InfoCards/InfoCards";
import ResumeStats from "./ResumeStats/ResumeStats";
import "./Welcome.scss";

export interface ComingSoonProps { }

export const ComingSoon: FunctionComponent<ComingSoonProps> = (props) => {
    const navigate = useNavigate("Home");
    const onHireAnExpertClicked = (e: SyntheticEvent, url: string) => navigate(e, url, "Hire An Expert Clicked");
    const onUnderstandingATSClicked = (e: SyntheticEvent, url: string) => navigate(e, url, "Understanding ATS Clicked");
    const onResumeGuideClicked = (e: SyntheticEvent, url: string) => navigate(e, url, "How To Create A Resume Clicked");

    return (
        <div className="wl-welcome-component mh-100">
            <section>
                <Hero
                    onHireAnExpert={onHireAnExpertClicked}
                />
            </section>
            <section>
                <InfoCards />
            </section>
            <section>
                <ResumeStats />
            </section>
            <section>
                <GettingStarted
                    onATSClicked={onUnderstandingATSClicked}
                    onHireAnExpertClicked={onHireAnExpertClicked}
                    onResumeGuideClicked={onResumeGuideClicked}
                />
            </section>
        </div>
    )
};

export default ComingSoon;
