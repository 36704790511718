const isDevEnvironment: boolean = process.env.NODE_ENV !== "production";
const isTestEnvironment: boolean = document.location.host.toLocaleLowerCase().indexOf("azurewebsites.net") >= 0;
const isProdEnvironment: boolean = !isDevEnvironment && !isTestEnvironment;

/** Constant configuration values. */
export const config = {

    /** The site's Amplitude client identifier. */
    amplitudeClientId: "6f5c6658f835ae313112e1bee76b866f",

    /** The root URL of the API. */
    apiRootUrl: isDevEnvironment ? "https://localhost:44311" : "",

    /** The default page title. */
    defaultPageTitle: "Resume Butlers - You Build Your Dreams, We Will Build Your Resume",

    /** A flag indicating whether the build is running in a development environment. */
    isDevEnvironment: isDevEnvironment,

    /** A flag indicating whether the build is running in a production environment. */
    isProdEnvironment: isProdEnvironment,

    /** The site's Google Analytics identifier. */
    googleAnalyticsId: "G-ZNFSMQ6M6D",

    /** PayPal client id. */
    paypalClientId: isProdEnvironment
        ? "AaAUoarMrczSKYm7qcGdPYUBsHuCWkQuGsGA9WKTxCeZ1JmpoW6DxhWlDYwOaxZYzY5koHZxuD0aVkX5"
        : "AZLTsrjoK1UFwS9yQLgd3PUQgQ6-OGv6f2RXycSOEUJ_49IFsi3KUdjKBT0naKXBOt_MmxvFq5csB0ZQ",
}

export default config;
