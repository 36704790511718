import { SyntheticEvent } from "react";
import { useHistory } from "react-router";
import analytics from "./analytics";

/**
 * A callback which preforms SPA navigation by canceling an anchor event and navigating to the specified URL.
 * @param {SyntheticEvent} e The anchor click/navigation event to cancel.
 * @param {string} url The URL to navigate to.
 * @param {string} eventName The optional name of an event to track via analytics.
 * @param {object} eventData The optional event data to track via analytics.
 */
export type NavigateFunc = (e: SyntheticEvent, url: string, eventName?: string, eventData?: {}) => void;

/**
 * A hook which preforms SPA navigation by canceling an anchor event and navigating the the specified URL.
 * @param {string} module The name of the current module.
 */
export const useNavigate = (module: string): NavigateFunc => {
    const history = useHistory();

    return (e: SyntheticEvent, url: string, eventName: string = null, eventData: {} = null) => {
        e.preventDefault();
        
        history.push(url);
        window.scroll(0, 1);

        if (eventName) {
            analytics.trackEvent(`${module}: ${eventName}`.trim(), eventData)
        }
    };
};

export default useNavigate;
