import axios from "axios";
import config from "../config";
import { Product } from "../models";

/** A service object for working with the Products API. */
export const productApi = {

    /** Gets all products from the API. */
    getProducts: (): Promise<Product[]> => axios.get(`${config.apiRootUrl}/api/products`)
        .then(response => response.data),

    /** Gets the identified product from the API. */
    getProduct: (id: number): Promise<Product> => axios.get(`${config.apiRootUrl}/api/products/${id}`)
        .then(response => response.data),

    /** Saves an product to the API. */
    saveProduct: (product: Product): Promise<void> => axios.post(`${config.apiRootUrl}/api/products`, product),
}

export default productApi;
