/** A classification of an Order. */
export enum OrderType {
    /**
     * Professional resume writing services.
     * @deprecated Use one of the other professional service types.
     */
    HireAnExpert = 1,

    /** Professional resume writing services. */
    ProfessionalResumeWriting = 10,

    /** A more details advanced review of an existing resume. */
    BasicResumeReview = 11,

    /** A deeper review of an existing resume. */
    AdvancedResumeReview = 12,

    /** Professional cover letter writing services. */
    ProfessionalCoverLetterWriting = 20,

    /** A quick, basic review of an existing cover letter. */
    BasicCoverLetterReview = 21,

    /** A more details advanced review of an existing cover letter. */
    AdvancedCoverLetterReview = 22,
};

export default OrderType;
