import { FunctionComponent } from "react";
import * as yup from "yup";
import { FormControl } from "../../../Form";

export interface WorkHistoryProps { }

const validation = yup.object({
    jobTitle: yup
        .string()
        .required(),
    employer: yup
        .string()
        .required(),
    city: yup
        .string()
        .required(),
    state: yup
        .string()
        .required(),
    startDate: yup
        .date()
        .required(),
    endDate: yup
        .date()
        .notRequired(),
});

export const WorkHistory: FunctionComponent<WorkHistoryProps> = (props) => {
    return (
        <div className="rb-work-history-component">
            <div className="row">
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="Job Title"
                        type="text"
                        placeHolder="e.g. Helpdesk Technician"
                        validation={validation.fields.jobTitle}
                    />
                </div>
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="Employer"
                        type="text"
                        placeHolder="e.g. Intel"
                        validation={validation.fields.employer}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="City"
                        type="text"
                        placeHolder="e.g. Santa Clara"
                        validation={validation.fields.city}
                    />
                </div>
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="State"
                        type="text"
                        placeHolder="e.g. California"
                        validation={validation.fields.state}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="Start Date"
                        type="text"
                        placeHolder="e.g. June 2018"
                        validation={validation.fields.startDate}
                    />
                </div>
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="End Date"
                        type="text"
                        placeHolder="e.g. September 2021"
                        validation={validation.fields.endDate}
                    />
                </div>
            </div>
        </div>
    )
}

export default WorkHistory;
