import axios from "axios";
import config from "../config";
import { Order, OrderType } from "../models";

/** A service object for working with the Orders API. */
export const orderApi = {

    /** Gets the next order number for the specified OrderType. */
    getNextOrderNumber: (orderType: OrderType): Promise<string> => axios.get<string>(`${config.apiRootUrl}/api/orders/${orderType}/next`)
        .then(response => response.data),

    /** Saves an order to the API. */
    saveOrder: (order: Order): Promise<void> => axios.post(`${config.apiRootUrl}/api/orders`, order),
}

export default orderApi;
