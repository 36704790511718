import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { analytics, useNavigate } from "../../../utilities";
import "./Footer.scss";

export interface FooterProps { }

export const Footer: FunctionComponent<FooterProps> = (props) => {
    const navigate = useNavigate("Footer");

    return (
        <div className="lo-footer-component">
            <div className="container">
                <div className="row ps-3 ps-sm-0 d-print-none">
                    <div className="col-12 col-sm-6 col-lg-4 col-xxl-3 mt-3">
                        <h6>
                            Cover Letters
                        </h6>
                        <ul>
                            <li>
                                <a
                                    href="/free-cover-letter-consultation"
                                    title="Free Cover Letter Consultation"
                                    onClick={e => navigate(e, "/free-cover-letter-consultation", "Free Cover Letter Consultation Clicked")}
                                >
                                    Free Cover Letter Consultation
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/professional-cover-letter-consultation"
                                    title="Professional Cover Letter Consultation"
                                    onClick={e => navigate(e, "/professional-cover-letter-consultation", "Professional Cover Letter Consultation Clicked")}
                                >
                                    Professional Cover Letter Consultation
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/professional-cover-letter-writing-services"
                                    title="Professional Cover Letter Services"
                                    onClick={e => navigate(e, "/professional-cover-letter-writing-services", "Professional Cover Letter Services Clicked")}
                                >
                                    Professional Cover Letter Services
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 col-xxl-3 mt-3">
                        <h6>
                            Resumes
                        </h6>
                        <ul>
                            <li>
                                <a
                                    href="/understanding-ats"
                                    title="Understanding ATS"
                                    onClick={e => navigate(e, "/understanding-ats", "Understanding ATS Clicked")}
                                >
                                    Understanding ATS
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/how-to-write-a-resume"
                                    title="Resume Writing Guide"
                                    onClick={e => navigate(e, "/how-to-write-a-resume", "Resume Writing Guide Clicked")}
                                >
                                    Resume Writing Guide
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/free-resume-consultation"
                                    title="Free Resume Consultation"
                                    onClick={e => navigate(e, "/free-resume-consultation", "Free Resume Consultation Clicked")}
                                >
                                    Free Resume Consultation
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/professional-resume-consultation"
                                    title="Professional Resume Consultation"
                                    onClick={e => navigate(e, "/professional-resume-consultation", "Professional Resume Consultation Clicked")}
                                >
                                    Professional Resume Consultation
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/professional-resume-writing-services"
                                    title="Professional Resume Writing Services"
                                    onClick={e => navigate(e, "/professional-resume-writing-services", "Professional Resume Writing Services Clicked")}
                                >
                                    Professional Resume Writing Services
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 col-xxl-3 mt-3">
                        <h6>
                            Company
                        </h6>
                        <ul>
                            <li>
                                <a
                                    href="/terms-and-conditions"
                                    title="Terms &amp; Conditions"
                                    onClick={e => navigate(e, "/terms-and-conditions", "Terms & Conditions Clicked")}
                                >
                                    Terms &amp; Conditions
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/privacy"
                                    title="Privacy Policy"
                                    onClick={e => navigate(e, "/privacy", "Privacy Policy Clicked")}
                                >
                                    Privacy Policy
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="d-lg-none d-xxl-block col-12 col-sm-6 col-lg-4 col-xxl-3 mt-3">
                        <h6>
                            Contact Us
                        </h6>
                        <ul>
                            <li>
                                <a
                                    href="https://www.facebook.com/ResumeButlers"
                                    target="_blank"
                                    rel="noopener nofollow" 
                                    title="Resume Butlers on Facebook"
                                    onClick={() => analytics.trackEvent("Footer: Facebook Link Clicked")}
                                >
                                    <FontAwesomeIcon
                                        icon={faFacebook}
                                        size="1x"
                                        className="me-1"
                                    />
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/resume_butlers"
                                    target="_blank"
                                    rel="noopener nofollow" 
                                    title="Resume Butlers on Instagram"
                                    onClick={() => analytics.trackEvent("Footer: Instagram Link Clicked")}
                                >
                                    <FontAwesomeIcon
                                        icon={faInstagram}
                                        size="1x"
                                        className="me-1"
                                    />
                                    Instagram
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="small text-center text-muted pb-3">
                    &copy; { new Date().getFullYear() === 2021 ? "2021" : `2021 - ${new Date().getFullYear()}` } Resume Butlers LLC
                </div>
            </div>
        </div>
    );
};

export default Footer;
