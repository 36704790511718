import { FunctionComponent } from "react";
import { ProductLineItem } from "../../../models";
import "./OrderLineItems.scss";

export interface OrderLineItemsProps {
    lineItems: ProductLineItem[];
}

export const OrderLineItems: FunctionComponent<OrderLineItemsProps> = (props) => {
    return (
        <div className="ck-order-line-items-component pb-3">
            {(props.lineItems || []).map((li, idx) => (
                <div key={idx} className={idx === 0 ? "mt-4" : null}>
                    <hr className={idx === 0 ? "d-none" : null} />
                    <div className="row">
                        <div className="col-12 col-sm-9 text-center text-sm-start">
                            <strong className="d-block d-sm-inline">{ li.name }</strong>
                            &nbsp;
                            <br className="d-none d-sm-block" />
                            { li.description }
                        </div>
                        <div className="col-12 col-sm-3 text-center text-sm-end fw-bold fw-md-normal mt-2 mt-sm-0">
                            ${ (li.price || 0).toFixed(2) }
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default OrderLineItems;
