import CheckoutPage from './CheckoutPage';

export const BasicResumeReview: CheckoutPage = {
    title: "Get a Free Expert Review",
    subtitle: "Have one of our resume experts review your resume for free.",
    checkoutTitle: "Let one of our resume experts do a quick review of your resume.",
    steps: [
        {
            title: "Contact Information",
            text: `
Provide us with some basic contact information so we can get in touch with you.
            `,
        },
        {
            title: "Consultation",
            text: `
Within a few days one of our resume experts will reach out to setup a 1 on 1 consultation via email or phone.
During the consultation our resume expert will spend 5 - 10 minutes giving you some tips and optimizations about your resume.
            `,
        },
    ],
};

export default BasicResumeReview;
