import amplitude, { AmplitudeClient } from "amplitude-js";
import config from "../config";

const amp: AmplitudeClient = amplitude.getInstance();
amp.init(config.amplitudeClientId);

/** A utility for working with analytical data. */
export const analytics = {

    /** Tracks a page view. */
    trackPageView: (pageTitle?: string, pageURL?: string): void => {
        const data = {
            page_title: pageTitle || document.title || config.defaultPageTitle,
            page_url: pageURL || document.location.toString(),
        };

        analytics.trackEvent("Page View", data);
    },

    /** Tracks an event. */
    trackEvent: (event: string, data?: {}): void => {
        if (event && config.isProdEnvironment) {
            const properties = {
                ...(data || {}),
                page_title: document.title,
                page_url: document.location.toString(),
            };

            if (event === "Page View") {
                gtag("config", config.googleAnalyticsId, properties);
            } else {
                gtag("event", event, properties);
            }

            amp.logEvent(event, properties);
        }
    },
};

export default analytics;
