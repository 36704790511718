import CheckoutPage from './CheckoutPage';

export const ProfessionalCoverLetterWriting: CheckoutPage = {
    title: "Hire A Cover Letter Expert",
    subtitle: "Have one of our experts bring your cover letter up-to-date.",
    checkoutTitle: "Let one of our experts create your best cover letter.",
    steps: [
    //     {
    //         title: "Upload Your Current Cover Letter",
    //         text: `
    // We'll ask you to upload your current cover letter. If you don't have one, no worries!
    // Just upload a document containing your past work history, education, and any accolades you'd like listed.
    //         `,
    //     },
    //     {
    //         title: "Checkout",
    //         text: `
    // Complete the checkout and your order will be on its way!
    // No worries, if we don't think we can improve your cover letter or you decide you'd like to cancel before we get started
    // you can reach out to us and we'll refund your order in full.
    //         `,
    //     },
        {
            title: "Purchase",
            text: `
Complete the checkout and your order will be on its way!
No worries, if we don't think we can improve your cover letter or you decide you'd like to cancel before we get started
you can reach out to us and we'll refund your order in full.
            `,
        },
        {
            title: "Consultation",
            text: `
Within a few days one of our experts will reach out to setup a 1 on 1 consultation via email or phone.
This consultation will give us an idea of what your job and career goals are and how we can best tailor your cover letter.
We'll get your current cover letter or job and education history from you so we can start working on your cover letter.
            `,
        },
        {
            title: "Review Your New Cover Letter",
            text: `
After your consultation our cover letter expert may take 5 business days to complete your cover letter.
Afterwards you'll recieve an editable copy of your cover letter along with a 10 - 20 minute video explaining
the cover letter and possible alternatives you can make.
            `,
        },
        {
            title: "Revisions",
            text: `
Your purchase entitles you to two free professional revisions to your cover letter.
If there are alterations you'd like us to make for any reason we'll get them taken care of.
            `,
        },
    ],
};

export default ProfessionalCoverLetterWriting;
