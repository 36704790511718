import { faBriefcase, faFileAlt, faUser, faWindow } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import "./ResumeStats.scss";

export interface ResumeStatsProps { }

export const ResumeStats: FunctionComponent<ResumeStatsProps> = (props) => {
    return (
        <div className="wl-resume-stats-component">
            <div className="container-fluid mt-3 py-4 text-center bg-light">
                <div className="row">
                    <div className="col-12 col-sm-10 col-xxl-8 offset-sm-1 offset-xxl-2">
                        <p className="h5 fw-normal">
                            Resume Butlers is dedicated to providing top resume writing services online utilizing professional resume writers.
                            <span className="d-block d-lg-inline-block mb-n3">&nbsp;</span>
                            Our staff are certified professional resume writers experienced in offering affordable student, associate, management, and executive
                            resume reviews and creations.
                        </p>
                        <p className="h5 fw-normal">
                            Taking time to tailor your resume to your job posting will increase your chances to get that interview by over 70%.
                            Here are some current statistics within the employment world. 
                        </p>
                    </div>
                </div>
                <div className="d-block d-md-flex justify-content-around mt-3">
                    <h5 className="h4 mt-5 mx-auto mx-md-3">
                        <FontAwesomeIcon
                            icon={faBriefcase}
                            size="3x"
                            className="d-block mx-auto text-muted mb-1"
                        />
                        Around 10% of all applications obtain an interview
                    </h5>
                    <h5 className="h4 mt-5 mx-auto mx-md-3">
                        <FontAwesomeIcon
                            icon={faFileAlt}
                            size="3x"
                            className="d-block mx-auto text-muted mb-1"
                        />
                        Unprofessional emails lead to 70%+ discarded resumes
                    </h5>
                    <h5 className="h4 mt-5 mx-auto mx-md-3">
                        <FontAwesomeIcon
                            icon={faUser}
                            size="3x"
                            className="d-block mx-auto text-muted mb-1"
                        />
                        75%+ resumes with a photo of the applicant gets rejected
                    </h5>
                    <h5 className="h4 mt-5 mx-auto mx-md-3">
                        <FontAwesomeIcon
                            icon={faWindow}
                            size="3x"
                            className="d-block mx-auto text-muted mb-1"
                        />
                        ATS is responsible for eliminating over 70% of resumes
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default ResumeStats;
