import { PageInfo } from "./models";

export const pages: PageInfo[] = [
    {
        name: "Home",
        url: "https://www.resumebutlers.com",
        path: "/",
        title: "",
        description: "White glove services for building the best resumes. Free resume tools and expert resume assistance."
    },
    {
        name: "Resume Writing Guide",
        url: "https://www.resumebutlers.com/how-to-write-a-resume",
        path: "/how-to-write-a-resume",
        title: "Resume Writing Guide",
        description: "An expert guide on writing your best resume. Tailored for ATS parsing and recruiting and hiring personnel."
    },
    {
        name: "Understanding ATS",
        url: "https://www.resumebutlers.com/understanding-ats",
        path: "/understanding-ats",
        title: "Understanding ATS",
        description: "Learn about Applicant Tracking Systems (ATS) and how they affect your resume."
    },
    {
        name: "Professional Resume Writing Services",
        url: "https://www.resumebutlers.com/professional-resume-writing-services",
        path: "/professional-resume-writing-services",
        title: "Professional Resume Writing Services",
        description: "Have one of our resume experts revise or create your resume."
    },
    {
        name: "Free Resume Consultation",
        url: "https://www.resumebutlers.com/free-resume-consultation",
        path: "/free-resume-consultation",
        title: "Free Resume Consultation",
        description: "Have one of our resume experts review your resume for free."
    },
    {
        name: "Professional Resume Consultation",
        url: "https://www.resumebutlers.com/professional-resume-consultation",
        path: "/professional-resume-consultation",
        title: "Professional Resume Consultation",
        description: "Have one of our resume experts do a thorough review of your resume."
    },
    {
        name: "Professional Cover Letter Services",
        url: "https://www.resumebutlers.com/professional-cover-letter-writing-services",
        path: "/professional-cover-letter-writing-services",
        title: "Professional Cover Letter Services",
        description: "Have one of our experts revise or create your cover letter."
    },
    {
        name: "Free Cover Letter Consultation",
        url: "https://www.resumebutlers.com/free-cover-letter-consultation",
        path: "/free-cover-letter-consultation",
        title: "Free Cover Letter Consultation",
        description: "Have one of our experts review your cover letter for free."
    },
    {
        name: "Professional Cover Letter Consultation",
        url: "https://www.resumebutlers.com/professional-cover-letter-consultation",
        path: "/professional-cover-letter-consultation",
        title: "Professional Cover Letter Consultation",
        description: "Have one of our experts do a thorough review of your cover letter."
    },
    {
        name: "Resume Builder",
        url: "https://www.resumebutlers.com/resume-builder",
        path: "/resume-builder",
        title: "Free Resume Builder",
        description: "Build a professional, ATS compatible resume with the tools from Resume Butlers"
    },
    {
        name: "Terms & Conditions",
        url: "https://www.resumebutlers.com/terms-and-conditions",
        path: "/terms-and-conditions",
        title: "Terms & Conditions",
        description: "Resume Butlers Terms & Conditions"
    },
    {
        name: "Privacy Policy",
        url: "https://www.resumebutlers.com/privacy",
        path: "/privacy",
        title: "Privacy Policy",
        description: "Resume Butlers Privacy Policy"
    }
];

export default pages;
