import CheckoutPage from './CheckoutPage';

export const AdvancedResumeReview: CheckoutPage = {
    title: "Get an Expert Review",
    subtitle: "Have one of our resume experts review your resume.",
    checkoutTitle: "Let one of our resume experts do a review of your resume.",
    steps: [
        {
            title: "Purchase",
            text: `
Complete the checkout and your order will be on its way!
No worries, if we don't think your resume can be improved or you decide you'd like to cancel before we get started
you can reach out to us and we'll refund your order in full.
            `,
        },
        {
            title: "Consultation",
            text: `
Within a few days one of our resume experts will reach out to setup a 1 on 1 consultation via email or phone.
This consultation will give us an idea of what your job and career goals are and how we can best tailor your resume.
We'll get your current resume from you so we can have our experts start their review.
            `,
        },
        {
            title: "Video Review",
            text: `
We'll email you a 10 - 20 minute video review of your resume. We'll outline things you've done well, provide suggestions for improvement, and point out concerns.
We'll provide examples on how to implement our suggestions and go over best some best practices.
            `,
        }
    ],
};

export default AdvancedResumeReview;
