import { FunctionComponent } from "react";
import * as yup from "yup";
import { Resume } from "../../../models";
import { FormControl } from "../../Form";
import "./ContactInfo.scss";

export interface ContactInfoProps { }

type ResumeContactInfo = Pick<Resume,
    "firstName"
    | "lastName"
    | "phoneNumber"
    | "emailAddress"
    | "streetAddress1"
    | "streetAddress2"
    | "city"
    | "state"
    | "postalCode"
>;

const validation = yup.object({
    firstName: yup
        .string()
        .label("First Name")
        .required(),
    lastName: yup
        .string()
        .label("Last Name")
        .required(),
    phoneNumber: yup
        .string()
        .label("Phone Number")
        .notRequired(),
    emailAddress: yup
        .string()
        .label("Email Address")
        .notRequired(),
    streetAddress1: yup
        .string()
        .label("Street Address 1")
        .notRequired(),
    streetAddress2: yup
        .string()
        .label("Street Address 2")
        .notRequired(),
    city: yup
        .string()
        .label("City")
        .notRequired(),
    state: yup
        .string()
        .label("State")
        .notRequired(),
    postalCode: yup
        .string()
        .label("Postal Code")
        .notRequired(),
}).required();

export const ContactInfo: FunctionComponent<ContactInfoProps> = (props) => {
    return (
        <div className="rb-contact-info-component">
            <h2>
                Contact Information
            </h2>
            <p>
                Lets get some information on who you are and how potential employers may contact you.
            </p>
            <div className="row">
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="First Name"
                        placeHolder="e.g. Jamie"
                        validation={validation.fields.firstName}
                    />
                </div>
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="Last Name"
                        placeHolder="e.g. Wright"
                        validation={validation.fields.lastName}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-3">
                    <FormControl
                        name="Address"
                        placeHolder="e.g. 123 E Main St"
                        validation={validation.fields.streetAddress1}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 mt-3">
                    <FormControl
                        name="City"
                        placeHolder="e.g. Las Vegas"
                        validation={validation.fields.city}
                    />
                </div>
                <div className="col-12 col-md-3 mt-3">
                    <FormControl
                        name="State"
                        placeHolder="e.g. Nevada"
                        validation={validation.fields.state}
                    />
                </div>
                <div className="col-12 col-md-3 mt-3">
                    <FormControl
                        name="Postal Code"
                        placeHolder="e.g. 89199"
                        type="number"
                        validation={validation.fields.postalCode}
                    />
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;
