import { FunctionComponent } from "react";
import { useNavigate } from "../../utilities";
import "./HowToWriteAResume.scss";

let idSuffix = 0;

export interface ResumeTipsProps { }

export const ResumeTips: FunctionComponent<ResumeTipsProps> = (props) => {
    const navigate = useNavigate("How To Write A Resume");

    return (
        <div className="htrw-how-to-write-a-resume-component">
            <div>
                <section className="container-fluid p-0">
                    <div className="hero">
                        <div className="row mx-0">
                            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4 offset-lg-5 offset-xl-6 offset-xxl-7 text-center">
                                <div className="hero-text p-3">
                                    <h1>
                                        How to Write a Resume
                                    </h1>
                                    <hr />
                                    <p className="h4 mb-0">
                                        We'll go over some of the do's and don'ts of a resume.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container py-3">
                    <p className="h4 text-center fw-normal">
                        Here's a list of some of the do's, don'ts, and other tips from our resume experts.
                    </p>
                    <div className="text-center">
                        <p className="d-inline d-lg-block mb-0">
                            These tips are tailored to help get your resume through ATS while looking professional to a hiring agent.
                        </p>
                        <p className="d-inline d-lg-block ps-1 ps-lg-0">
                            If you're not familiar with ATS (Applicant Tracking Systems), please read our <a
                                href="/understanding-ats"
                                onClick={e => navigate(e, "/understanding-ats", "Understanding ATS Clicked")}
                                title="Understanding ATS"
                            >
                                Understanding ATS
                            </a> guide before continuing.
                        </p>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-center table-of-contents">
                            <h2 className="h5">Table of Contents</h2>
                            <ul>
                                <li>
                                    <a href="#file-type">File Type</a>
                                </li>
                                <li>
                                    <a href="#fonts">Fonts</a>
                                </li>
                                <li>
                                    <a href="#images-and-graphics">Images &amp; Graphics</a>
                                </li>
                                <li>
                                    <a href="#resume-title">Resume Title</a>
                                </li>
                                <li>
                                    <a href="#resume-sections">Resume Sections</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <section>
                                <a id="file-type" />
                                <h2 className="h4">
                                    File Type
                                </h2>
                                <p className="mb-0">
                                    A PDF document is typically the best choice when sending a resume; it opens on any system and is easily shared.
                                </p>
                                <p>
                                    ATS handles PDF documents as well as Microsoft® Word or other OpenDocument files (i.e. Google Docs).
                                </p>
                                <p>
                                    Avoiding using Rich Text Format (.rtf) or Text (.txt) files, or any other file format that people may not be familiar with.
                                    Your resume needs to be a recognizable to the recipient and needs to open on a mobile phone, desktop computer and a web browser.
                                </p>
                            </section>
                            <section>
                                <a id="fonts" />
                                <h2 className="h4">
                                    Fonts
                                </h2>
                                <p>
                                    <strong>Body Text Font Size:</strong> 10 – 12pt
                                </p>
                                <p>
                                    <strong>Headers:</strong> 2 – 3pt higher than the body text
                                </p>
                                <p>
                                    <strong>Resume Title Font Size:</strong> 22 – 26pt
                                </p>
                                <p>
                                    <strong>Best font choices:</strong> Arial, Cambria, Calibri, Didot, Garamond, Times New Roman, Helvetica
                                </p>
                                <p>
                                    Most employers use an Applicant Tracking System (ATS); these systems have a hard time reading eccentric fonts.
                                    It is best to keep things simple. Utilizing a professional and easy to read font helps your resume get through ATS easier.
                                    This also helps the reader to follow along and understand the information provided. 
                                </p>
                                <p>
                                    When choosing between a Serif and Sans Serif fonts (Serif: with tails) (Sans Serif: without tails) keep in mind Sans Serif
                                    is easier for the ATS to read and look more professional; however, employers have expressed that Cambria, Garamond, Times New Roman,
                                    and Didot are accepted within the serifed fonts.
                                </p>
                            </section>
                            <section>
                                <a id="images-and-graphics" />
                                <h2 className="h4">
                                    Images &amp; Graphics
                                </h2>
                                <p>
                                    ATS does not read images or graphics inside of a resume, so if you add an image of your school for your education section
                                    make sure to add text as well so ATS can pick up the information.
                                    Too many graphics can lead to a loss of information when it parses into the applicant tracking system. 
                                </p>
                                <p>
                                    Using photos of yourself on your resume <em>could</em> lead to unconscious or conscious biases and could be a reason for disqualification.
                                </p>
                            </section>
                            <section>
                                <a id="resume-title" />
                                <h2 className="h4">
                                    Resume Title
                                </h2>
                                <p className="mb-1">
                                    ATS has a difficult time reading text inside of the header and footer section of a Word or Google document.
                                    It is best to keep your title on your first page outside of the header.
                                    When creating a resume title remember to put information that is important to the recruiter/hiring manager.
                                </p>
                                <ol>
                                    <li>
                                        Name
                                    </li>
                                    <li>
                                        City &amp; State
                                    </li>
                                    <li>
                                        Phone Number &amp; Email Address
                                    </li>
                                    <li>
                                        Portfolio, LinkedIn, or Website addresses
                                    </li>
                                </ol>
                            </section>
                            <section>
                                <a id="resume-sections" />
                                <h2 className="h4">
                                    Resume Sections
                                </h2>
                                <ul>
                                    <li>
                                        Contact Information
                                    </li>
                                    <li>
                                        Executive Summary, Summary, Objective, or Profile
                                    </li>
                                    <li>
                                        Work Experience
                                    </li>
                                    <li>
                                        Education
                                    </li>
                                    <li>
                                        Skills, Skills &amp; Qualifications, Technical Skills, Core Competencies, or Soft Skills
                                    </li>
                                </ul>
                                <p>
                                    When figuring out what sections to use in your resume you must figure out what is most important to the job postings.
                                    It is best not to use pronouns in your resume and to keep things subjective.
                                </p>
                                <p>
                                    Place your most relevant information from top to bottom.
                                    If you have more experience in the industry than your education, you should place your experience on the top.
                                    If you have more education than experience than your education and skills should be placed on top. 
                                </p>
                                <h3 className="h5">
                                    Contact Information
                                </h3>
                                <p>
                                    If you do not have contact information the hiring manager will not be able to reach out to you. 
                                    Some things to keep in mind are to make sure the phone number you place on your resume is one where the employer can easily reach you,
                                    make sure your voicemail is set up, make sure there is room for an employer to leave a message, and make sure it is somewhat professional.
                                    If an employer is unable to leave a message to let you know how to get back to them it will be difficult to set up an interview.
                                    If your voicemail is unprofessional there is a chance the recruiter will skip you and go to the next candidate.
                                </p>
                                <h4 className="h6">
                                    Summary
                                </h4>
                                <p>
                                    This is going to be your first introduction to the employer make sure to make it will catch their attention!
                                    Summaries are best if they are between 2-3 sentences and are used when you are already working in the industry and are just changing
                                    companies or positions. Showcase your highlights by introducing your education, experience, proven successes, and certifications.
                                    Think of this as a sales pitch similar to a 10-second elevator pitch.
                                </p>
                                <div className="border p-3 mb-3">
                                    <strong>Example</strong>
                                    <br />
                                    Customer-oriented sales representative with a bachelor’s degree in business administration with a Minor in Sales and 5+ years of
                                    experience in (B2B) Business to Business sales, (B2C) Business to consumer sales, Customer Service, and Office Management.
                                    Proven success generating over $400,000 in sales for high volume clients increasing overall revenue by 10%.
                                </div>
                                <h3 className="h5">
                                    Objective Statement
                                </h3>
                                <p>
                                    This is going to be your first introduction to the employer make sure to make it will catch their attention!
                                    Objectives are best if they are between 2-3 sentences and are used when you are just coming out of school,
                                    have no experience in the industry, or are changing careers.
                                    Make an introduction! Use this as a chance to let the employer know what you want in a way that does not sound like “GIVE ME!” 
                                </p>
                                <div className="border p-3 mb-3">
                                    <strong>Example</strong>
                                    <br />
                                    Industrial Automation student/graduate with 2+ years of experience in quality control, quality assurance, and automotive.
                                    Looking for an entry level “Maintenance Tech” position to assist an established company while growing skills and experience.   
                                </div>
                                <h3 className="h5">
                                    Work Experience
                                </h3>
                                <p>
                                    Work experience showcases your ability to perform the duties within the job posting, show your ability to stay with a company,
                                    and to sell yourself while painting a picture in the minds of the employers. You should think about utilizing either the Situation,
                                    Task, Action, Result (S.T.A.R) method or the What did you do? How did you do it? What was the outcome? (W.H.O) method.
                                </p>
                                <p>
                                    Quantifying your experience helps show the scope of your abilities, helps paint a clear picture into the minds of the employer, and sells your abilities. 
                                </p>
                                <p>
                                    Bullet points are still the easiest way for the reader to follow the information in the resume, locate information quickly, and be easily digestible.
                                    Bullet points should start with an action verb, be 1 sentence, and 1-2 lines.
                                </p>
                                <p>
                                    For best results your first position should be your newest going in reverse chronological order and be 4-8 bullet points, older positions should be 3-5 bullet points. 
                                </p>
                                <div className="border p-3 mb-3">
                                    <strong>W.H.O. Example</strong>
                                    <br />
                                    Educated 30+ personnel in sales strategies and customer relations increasing customer retention by over 30%.
                                    <ul>
                                        <li>
                                            What did you do: Educated 30+ personnel
                                        </li>
                                        <li>
                                            How did you do it: sales strategies and customer relations
                                        </li>
                                        <li>
                                            What was the outcome: increasing customer retention by 30%.
                                        </li>
                                    </ul>
                                </div>
                                <div className="border p-3 mb-3">
                                    <strong>S.T.A.R. Example</strong>
                                    <br />
                                    Assigned 6 high profile clients that had a track record of backing out of contracts providing an action plan, constant communication,
                                    and actively listening lead to 4/5 clients completing the signing
                                    <ul>
                                        <li>
                                            Situation: Assigned 6 high profile clients
                                        </li>
                                        <li>
                                            Task: had a track record of backing out of contracts
                                        </li>
                                        <li>
                                            Action: providing an action plan, constant communication, and actively listening
                                        </li>
                                        <li>
                                            Result: lead to 4/5 clients completing the signing
                                        </li>
                                    </ul>
                                </div>
                                <h3 className="h5">
                                    Education
                                </h3>
                                <p>
                                    When you are placing your education, you want the name of your degree (i.e. Associates of Science in Business Administration).
                                    Use the name of the school you graduated from, if you went to multiple schools to obtain the one degree you will just
                                    use the name of the last school you attended (i.e. ABC School of Business and Science).
                                </p>
                                <p>
                                    We recommend placing just the year of graduation or expected graduation for the date. (i.e. 2021, 2025, or 2025 Projected).
                                </p>
                                <p>
                                    After you place the highest degree on top the rest should be in reverse chronological order, or newest to oldest. 
                                </p>
                                <div className="border p-3 mb-3">
                                    <strong>Example</strong>
                                    <div className="row">
                                        <div className="col-12 col-lg-11">
                                            <pre>
                                                <span className="fw-bolder">
                                                    Associates of Science in Business Administration,
                                                </span>
                                                &nbsp;ABC Business University
                                                <span className="d-lg-none">
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2021
                                                </span>
                                            </pre>
                                        </div>
                                        <div className="col d-none d-lg-block text-end">
                                            <pre>2021</pre>
                                        </div>
                                    </div>
                                    <strong>Example 2</strong>
                                    <div className="row">
                                        <div className="col-12 col-lg-11">
                                            <pre>
                                                <span className="fw-bolder">
                                                    ABC Business University | Los Angeles, CA
                                                </span>
                                                <span className="d-lg-none">
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2021
                                                </span>
                                                <br />
                                                <span className="ps-3 ps-lg-5 fst-italic">
                                                    Associates of Science in Business Administration
                                                </span>
                                            </pre>
                                        </div>
                                        <div className="col d-none d-lg-block text-end">
                                            <pre>2021</pre>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="h5">
                                    Skills
                                </h3>
                                <p>
                                    This is a great opportunity to easily place information to attract the employer.
                                    Skills can be used to hit more keywords in the job posting (to help get past ATS) and be a quick reference for your scope of abilities.
                                </p>
                                <p>
                                    If you are in a computer science field think about using technical skills and Core Competencies.
                                    Business related fields can use Skills &amp; Qualifications.
                                    Industrial, manufacturing, and production fields can be use Hard skills and Soft Skills. 
                                </p>
                                <p>
                                    You can use a horizontal list, or a 2-column bullet pointed list.
                                </p>
                                <div className="border p-3 mb-3">
                                    <strong>Example 1</strong>
                                    <br />
                                    Technical Skills:
                                    <hr className="my-1" />
                                    Adobe Premier, Photoshop, Microsoft Office, Word, Excel, PowerPoint, Graphic Design, Coding, C++, C#, Python, …
                                    <br />
                                    <br />
                                    <strong>Example 2</strong>
                                    <br />
                                    Technical Skills:
                                    <hr className="my-1" />
                                    <div className="row">
                                        <div className="col-6 ps-md-3 ps-lg-5">
                                            <ul>
                                                <li>
                                                    Adobe Premier
                                                </li>
                                                <li>
                                                    Photoshop
                                                </li>
                                                <li>
                                                    Microsoft Office
                                                </li>
                                                <li>
                                                    Word
                                                </li>
                                                <li>
                                                    Excel
                                                </li>
                                                <li>
                                                    PowerPoint
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-6 ps-md-3">
                                            <ul>
                                                <li>
                                                    Graphic Design
                                                </li>
                                                <li>
                                                    Coding
                                                </li>
                                                <li>
                                                    C++
                                                </li>
                                                <li>
                                                    C#
                                                </li>
                                                <li>
                                                    Python
                                                </li>
                                                <li>
                                                    ...
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="h5">
                                    Optional Sections
                                </h3>
                                <ul>
                                    <li>
                                        Additional Experience (Optional if you have experience outside of 15 years or positions you worked at for less than a year)
                                    </li>
                                    <li>
                                        Accomplishments
                                    </li>
                                    <li>
                                        Volunteer Work
                                    </li>
                                    <li>
                                        Certifications and Licenses
                                    </li>
                                    <li>
                                        Awards
                                    </li>
                                    <li>
                                        Associations
                                    </li>
                                    <li>
                                        Publications
                                    </li>
                                    <li>
                                        Testimonials
                                    </li>
                                    <li>
                                        Executive Core Qualifications
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ResumeTips;
