import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { AnySchema } from "yup";
import "./FormControl.scss";

export interface FormControlProps {
    name: string;
    type?: "text" | "number" | "email" | "tel";
    placeHolder?: string;
    validation?: AnySchema;
    value?: string | number;
    min?: number;
    max?: number;
    maxLength?: number;
    autocomplete?: "off" | "on" | "name" | "given-name" | "family-name" | "address-line1" | "address-line2" | "address-line3" | "address-level4" | "address-level3" | "address-level2" | "address-level1" | "country" | "country-name" | "postal-code" | "cc-name" | "cc-given-name" | "cc-additional-name" | "cc-family-name" | "cc-number" | "cc-exp" | "cc-exp-month" | "cc-exp-year" | "cc-csc" | "cc-type" | "transaction-currency" | "transaction-amount" | "language" | "bday" | "bday-day" | "bday-month" | "bday-year" | "email" | "username" | "current-password" | "new-password" | "tel";
    onValidate?: (fieldName: string, isValid: boolean) => void;
    onChange?: (val: string | number) => void;
}

const fieldNumber: number = 0;

export const FormControl: FunctionComponent<FormControlProps> = (props) => {
    const ref = useRef<HTMLInputElement>();
    const [value, setValue] = useState<string | number>(props?.value);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const fieldName = `form-control-${fieldNumber}`;
    const onChange = (val: string | number, isEffect: boolean = false) => {
        let error: string = "";

        try {
            props.validation?.validateSync(value);
        } catch (err) {
            error = (typeof err === "string" ? err : err instanceof Error ? err.message : null) || "Please provide a valid input.";
        }

        if (!isEffect) {
            setIsDirty(true);
        }
        ref?.current?.setCustomValidity(error);
        setValue(val);
        setError(error);

        if (!error) {
            props?.onChange(val);
        }
    };
    useEffect(() => {
        onChange(value, true);
    }, [value]);

    return (
        <div>
            <label htmlFor={fieldName}>
                { props.name }
            </label>
            <div className="fm-form-control input-group">
                <input
                    id={fieldName}
                    ref={ref}
                    type={props.type || "text"}
                    className="form-control"
                    autoComplete={props.autocomplete || "on"}
                    placeholder={props.placeHolder || ""}
                    name={props.name}
                    aria-label={props.name}
                    min={props.min}
                    max={props.max}
                    maxLength={props.maxLength}
                    value={typeof value !== "undefined" && value !== null ? value : ""}
                    onChange={e => onChange(e.target.value)}
                />
                <div className={`validation ${!isDirty ? "d-none" : null}`}>
                    <FontAwesomeIcon
                        icon={ref?.current?.validity.valid ? faCheckCircle : faTimesCircle}
                        className={`${!isDirty ? "d-none" : ""} ${ref?.current?.validity.valid ? "text-success" : "text-danger"}`}
                    />
                </div>
            </div>
        </div>
    );
}

export default FormControl;
