import { loadScript } from "@paypal/paypal-js";
import config from "../../config";
import { Product } from "../../models";

export const initPayPal = (product: Product): Promise<OrderData> => loadScript({
    "client-id": config.paypalClientId,
    "currency": "USD",
    "intent": "capture",
    "debug": !config.isProdEnvironment,
}).then(pp => new Promise<OrderData>((resolve, reject) => pp.Buttons({
        style: {
            shape: 'pill',
            color: 'blue',
            layout: 'vertical',
            label: 'pay',
        },
        createOrder: function(data: any, actions: any) {
            return actions.order.create({
                "application_context": {
                    "shipping_preference": "NO_SHIPPING",
                },
                "currency": "USD",
                // https://developer.paypal.com/docs/api/orders/v2/#definition-purchase_unit_request
                "purchase_units": [{
                    "description": product.name,
                    "amount": {
                        "currency_code": "USD",
                        "value": product.price,
                        "breakdown": {
                            "item_total": {
                                "currency_code": "USD",
                                "value": product.price
                            },
                        },
                    },
                    "items": product.lineItems.map(item => ({
                        "name": item.name,
                        "description": item.description,
                        "category": "DIGITAL_GOODS",
                        "quantity": 1,
                        "unit_amount": {
                            "currency_code": "USD",
                            "value": item.price,
                        },
                    })),
                }],
            });
        },
        onApprove: function(data: any, actions: any) {
            return actions.order.capture()
                .then((orderData: OrderData) => resolve(orderData))
                .catch((err: any) => reject(err));
        },
        onCancel: function(data: any) {
            reject(new Error("Payment cancelled by user."));
        },
        onError: function(err: any) {
            reject(err);

            return err;
        },
    }).render("#paypal-button-container"))
);

export interface OrderData {
    "id": string,
    "status": string,
    "intent": string,
    "payer": {
        "name": {
        "given_name": string,
        "surname": string,
        },
        "email_address": string,
        "payer_id": string,
    },
    "purchase_units": [
        {
            "reference_id": string,
            "amount": {
                "currency_code": "USD",
                "value": string,
            },
        },
    ],
    "create_time": string,
}

export default initPayPal;
