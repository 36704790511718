import CheckoutPage from './CheckoutPage';

export const ProfessionalResumeWriting: CheckoutPage = {
    title: "Hire A Resume Expert",
    subtitle: "Have one of our resume experts bring your resume up-to-date.",
    checkoutTitle: "Let one of our resume experts create your best resume.",
    steps: [
    //     {
    //         title: "Upload Your Current Resume",
    //         text: `
    // We'll ask you to upload your current resume. If you don't have one, no worries!
    // Just upload a document containing your past work history, education, and any accolades you'd like listed.
    //         `,
    //     },
    //     {
    //         title: "Checkout",
    //         text: `
    // Complete the checkout and your order will be on its way!
    // No worries, if we don't think we can improve your resume or you decide you'd like to cancel before we get started
    // you can reach out to us and we'll refund your order in full.
    //         `,
    //     },
        {
            title: "Purchase",
            text: `
Complete the checkout and your order will be on its way!
No worries, if we don't think we can improve your resume or you decide you'd like to cancel before we get started
you can reach out to us and we'll refund your order in full.
            `,
        },
        {
            title: "Consultation",
            text: `
Within a few days one of our resume experts will reach out to setup a 1 on 1 consultation via email or phone.
This consultation will give us an idea of what your job and career goals are and how we can best tailor your resume.
We'll get your current resume or job and education history from you so we can start working on your resume.
            `,
        },
        {
            title: "Review Your New Resume",
            text: `
After your consultation our resume expert may take 5 business days to complete your resume.
Afterwards you'll recieve an editable copy of your resume along with a 10 - 20 minute video explaining
the resume and possible alternatives you can make.
            `,
        },
        {
            title: "Revisions",
            text: `
Your purchase entitles you to two free professional revisions to your resume.
If there are alterations you'd like us to make for any reason we'll get them taken care of.
            `,
        },
    ],
};

export default ProfessionalResumeWriting;
