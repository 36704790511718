import CheckoutPage from './CheckoutPage';

export const BasicCoverLetterReview: CheckoutPage = {
    title: "Get a Free Expert Review",
    subtitle: "Have one of our experts review your cover letter for free.",
    checkoutTitle: "Let one of our experts do a quick review of your cover letter.",
    steps: [
        {
            title: "Contact Information",
            text: `
Provide us with some basic contact information so we can get in touch with you.
            `,
        },
        {
            title: "Consultation",
            text: `
Within a few days one of our experts will reach out to setup a 1 on 1 consultation via email or phone.
During the consultation our expert will spend 5 - 10 minutes giving you some tips and optimizations about your cover letter.
            `,
        },
    ],
};

export default BasicCoverLetterReview;
