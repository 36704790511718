import { FunctionComponent, useMemo } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppInfo, OrderType, PageInfo } from "../models";
import pageInfo from "../pages";
import AtsHelp from "./AtsHelp/AtsHelp";
import Builder from "./Builder/Builder";
import Checkout from "./Checkout/Checkout";
import HireAnExpert from "./HireAnExpert/HireAnExpert";
import HowToWriteAResume from "./HowToWriteAResume/HowToWriteAResume";
import usePageLayout from "./Layout/Layout";
import Privacy from "./Privacy/Privacy";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import Welcome from "./Welcome/Welcome";

export interface AppProps { }

type Pages = {
    [key: string]: PageInfo;
}

export const App: FunctionComponent<AppProps> = (props) => {
    const appInfo: AppInfo = new AppInfo({pageInfo});
    const pages: Pages = useMemo(() => {
        const pages: Pages = {};
        for (let pageInfo of appInfo.pageInfo) {
            pages[pageInfo.path] = pageInfo;
        }
        return pages;
    }, []);
    const defaultPage = pages["/"];

    return (
        <div className="page-contents">
            <Router>
                <Switch>
                    { /* Hire an Expert */ }
                    <Route path="/hire-an-expert"> {/* LEGACY - DO NOT USE */}
                        {usePageLayout(HireAnExpert, {
                            pageInfo: pages["/hire-an-expert"] || defaultPage,
                            noPadding: true,
                            orderType: OrderType.ProfessionalResumeWriting,
                        })}
                    </Route>
                    <Route path="/professional-resume-writing-services">
                        {usePageLayout(HireAnExpert, {
                            pageInfo: pages["/professional-resume-writing-services"] || defaultPage,
                            noPadding: true,
                            orderType: OrderType.ProfessionalResumeWriting,
                        })}
                    </Route>
                    <Route path="/free-resume-consultation">
                        {usePageLayout(HireAnExpert, {
                            pageInfo: pages["/free-resume-consultation"] || defaultPage,
                            noPadding: true,
                            orderType: OrderType.BasicResumeReview,
                        })}
                    </Route>
                    <Route path="/professional-resume-consultation">
                        {usePageLayout(HireAnExpert, {
                            pageInfo: pages["/professional-resume-consultation"] || defaultPage,
                            noPadding: true,
                            orderType: OrderType.AdvancedResumeReview,
                        })}
                    </Route>
                    <Route path="/professional-cover-letter-writing-services">
                        {usePageLayout(HireAnExpert, {
                            pageInfo: pages["/professional-cover-letter-writing-services"] || defaultPage,
                            noPadding: true,
                            orderType: OrderType.ProfessionalCoverLetterWriting,
                        })}
                    </Route>
                    <Route path="/free-cover-letter-consultation">
                        {usePageLayout(HireAnExpert, {
                            pageInfo: pages["/free-cover-letter-consultation"] || defaultPage,
                            noPadding: true,
                            orderType: OrderType.BasicCoverLetterReview,
                        })}
                    </Route>
                    <Route path="/professional-cover-letter-consultation">
                        {usePageLayout(HireAnExpert, {
                            pageInfo: pages["/professional-cover-letter-consultation"] || defaultPage,
                            noPadding: true,
                            orderType: OrderType.AdvancedCoverLetterReview,
                        })}
                    </Route>

                    { /* SEO pages */ }
                    <Route path="/how-to-write-a-resume">
                        {usePageLayout(HowToWriteAResume, {
                            pageInfo: pages["/how-to-write-a-resume"] || defaultPage,
                            noPadding: true,
                        })}
                    </Route>
                    <Route path="/understanding-ats">
                        {usePageLayout(AtsHelp, {
                            pageInfo: pages["/understanding-ats"] || defaultPage,
                            noPadding: true,
                        })}
                    </Route>

                    { /* Unfortuanately required pages */}
                    <Route path="/privacy">
                        {usePageLayout(Privacy, {
                            pageInfo: pages["/privacy"] || defaultPage,
                        })}
                    </Route>
                    <Route path="/terms-and-conditions">
                        {usePageLayout(TermsAndConditions, {
                            pageInfo: pages["/terms-and-conditions"] || defaultPage,
                        })}
                    </Route>

                    <Route path="/checkout">
                        {usePageLayout(Checkout, {
                            pageInfo: pages["/checkout"] || defaultPage,
                            hideLinks: true,
                        })}
                    </Route>
                    <Route path="/resume-builder">
                        {usePageLayout(Builder, {
                            pageInfo: pages["/resume-builder"] || defaultPage,
                            hideLinks: true,
                        })}
                    </Route>
                    <Route path="/">
                        {usePageLayout(Welcome, {
                            pageInfo: pages["/"] || defaultPage,
                            noPadding: true,
                        })}
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
