import { faCalendarStar, faCog, faFileCertificate } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import "./InfoCards.scss";

export interface InfoCardsProps { }

export const InfoCards: FunctionComponent<InfoCardsProps> = (props) => {
    return (
        <div className="wl-info-cards-component">
            <div className="container mt-3 text-center">
                <h2 className="h3">
                    Take the guesswork out of resume writing.
                </h2>
                <div className="d-block d-lg-flex justify-content-evenly flex-wrap align-items-stretch mt-3">
                    <div className="card mt-3">
                        <div className="card-body">
                            <h5 className="card-title mb-2">
                                <FontAwesomeIcon
                                    icon={faFileCertificate}
                                    title="Certified Experts"
                                    size="3x"
                                    className="d-block mx-auto text-muted mb-1"
                                />
                                Certified Experts
                            </h5>
                            <p className="card-text">
                                Our experts are CPRW certified by The Professional Association of Résumé Writers.
                            </p>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <h5 className="card-title mb-2">
                                <FontAwesomeIcon
                                    icon={faCog}
                                    title="ATS Compliant"
                                    size="3x"
                                    className="d-block mx-auto text-muted mb-1"
                                />
                                ATS Compliant
                            </h5>
                            <p className="card-text">
                                Applicant Tracking Systems (ATS) are used by employers to automatically filter out candidate resumes.
                                We understand how these automated systems work and what they're looking for in a resume.
                            </p>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <h5 className="card-title mb-2">
                                <FontAwesomeIcon
                                    icon={faCalendarStar}
                                    title="5+ Years Experience"
                                    size="3x"
                                    className="d-block mx-auto text-muted mb-1"
                                />
                                5+ Years Experience
                            </h5>
                            <p className="card-text">
                                Our experts have over five years of experience in resume writing and recruiting.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoCards;
