import { faAddressBook, faAward, faBriefcase, faCheckSquare, faFileAlt, faGraduationCap } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import "./Progress.scss";

export interface ProgressProps {
    getStartedCompleted: boolean;
    contactInfoCompleted: boolean;
    experienceCompleted: boolean;
    educationCompleted: boolean;
    skillsCompleted: boolean;
    reviewCompleted: boolean;
}

export const Progress: FunctionComponent<ProgressProps> = (props) => {
    return (
        <div className="rb-progress-component text-center">
            <FontAwesomeIcon
                icon={faFileAlt}
                title="Get Started"
                className={props.getStartedCompleted ? "text-success" : "text-gray"}
                size="3x"
            />
            <div className={`bar ${props.getStartedCompleted ? "bar-success" : "bar-gray"}`} />
            <FontAwesomeIcon
                icon={faAddressBook}
                title="Contact Info"
                className={props.contactInfoCompleted ? "text-success" : "text-gray"}
                size="3x"
            />
            <div className={`bar ${props.experienceCompleted ? "bar-success" : "bar-gray"}`} />
            <FontAwesomeIcon
                icon={faBriefcase}
                title="Work History"
                className={props.experienceCompleted ? "text-success" : "text-gray"}
                size="3x"
            />
            <div className={`bar ${props.educationCompleted ? "bar-success" : "bar-gray"}`} />
            <FontAwesomeIcon
                icon={faGraduationCap}
                title="Education"
                className={props.educationCompleted ? "text-success" : "text-gray"}
                size="3x"
            />
            <div className={`bar ${props.skillsCompleted ? "bar-success" : "bar-gray"}`} />
            <FontAwesomeIcon
                icon={faAward}
                title="Skill"
                className={props.skillsCompleted ? "text-success" : "text-gray"}
                size="3x"
                />
            <div className={`bar ${props.reviewCompleted ? "bar-success" : "bar-gray"} d-none d-md-inline-block d-lg-block`} />
            <FontAwesomeIcon
                icon={faCheckSquare}
                title="Review"
                className={`${props.reviewCompleted ? "text-success" : "text-gray"} d-none d-md-inline-block`}
                size="3x"
            />
        </div>
    );
}

export default Progress;
