import { FunctionComponent, SyntheticEvent } from "react";
import "./Hero.scss";

export interface HeroProps {
    onHireAnExpert: (e: SyntheticEvent, url: string) => void;
}

export const Hero: FunctionComponent<HeroProps> = (props) => {
    return (
        <div className="wl-hero-component">
            <div className="hero">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-10 col-xl-5 col-xxl-4 offset-md-1">
                            <div className="hero-text p-3 text-center w-100">
                                <h1>
                                    Professional Resume Writing Services
                                </h1>
                                <hr className="my-1" />
                                <p className="m-0">
                                    Top-Tier Resume's &amp; Cover Letters
                                </p>
                                <div className="text-center d-xl-none">
                                    <button
                                        type="button"
                                        className="btn btn-primary text-white mt-3"
                                        onClick={e => props.onHireAnExpert(e, "/professional-resume-writing-services")}
                                    >
                                        Hire An Expert
                                    </button>
                                    <small className="d-block text-nowrap">
                                        Have one of our experts write your resume today.
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4 offset-xl-1 offset-xxl-2 d-none d-xl-block">
                            <div className="hero-text p-3 text-end w-100 bg-transparent border-0">
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-hero btn-primary text-white mt-3"
                                        onClick={e => props.onHireAnExpert(e, "/professional-resume-writing-services")}
                                    >
                                        Hire An Expert
                                    </button>
                                    <strong className="d-block text-nowrap">
                                        Have one of our experts write your resume today.
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
