import { FunctionComponent } from "react";
import "./Experience.scss";
import WorkHistory from "./WorkHistory/WorkHistory";

export interface ExperienceProps { }

export const Experience: FunctionComponent<ExperienceProps> = (props) => {
    return (
        <div className="rb-experience-component">
            <h2>
                Work History
            </h2>
            <p>
                Lets get information about your previous experience and work history.
            </p>
            <WorkHistory />
        </div>
    );
}

export default Experience;
