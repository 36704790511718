import { FunctionComponent } from "react";
import "./AtsHelp.scss";

export interface AtsHelpProps { }

export const AtsHelp: FunctionComponent<AtsHelpProps> = (props) => {
    return (
        <div className="ah-ats-help-component">
            <div>
                <section className="container-fluid p-0">
                    <div className="hero">
                        <div className="row mx-0">
                            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4 offset-lg-5 offset-xl-6 offset-xxl-7 text-center">
                                <div className="hero-text p-3">
                                    <h1>
                                        Understanding ATS
                                    </h1>
                                    <hr />
                                    <p className="h4 mb-0">
                                        ATS, or Applicant Tracking Systems, are suites of software that screen and filter resumes and job applicants before a human ever sees your resume.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container-fluid py-5">
                    <div className="row">
                        <div className="col-2 offset-1 d-none d-md-block text-md-end">
                            <img
                                src="/images/understanding-ats/ats-system.png"
                                title="Applicant Tracking Systems"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-12 col-md-9 col-xl-7">
                            <h2 className="h3">
                                What Are Applicant Tracking Systems (ATS)
                            </h2>
                            <p>
                                Applicant tracking systems, or ATS, are suites of software used by companies to create, publish, and track job listings.
                            </p>
                            <p className="mb-0">
                                The ATS system automatically screens resumes against the job posting and filters out <em>poor</em> applicant matches.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="container-fluid py-5 bg-light">
                    <div className="row">
                        <div className="col-12 offset-md-1 offset-lg-2 col-md-8 col-xl-6">
                            <h2 className="h3">
                                ATS Filters Out 70% of Applicants
                            </h2>
                            <p>
                                Studies show that applicant tracking systems filter out about 70% of all applicants without a human ever seeing an applicant.
                            </p>
                            <p className="mb-0">
                                70% of the time a human being never even knows you applied for their job!
                            </p>
                        </div>
                        <div className="col-2 col-xl-3 d-none d-md-block">
                            <img
                                src="/images/understanding-ats/ats-filtering.png"
                                title="ATS Filters Out 70% of Candidates"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>
                <section className="container-fluid py-5">
                    <div className="row">
                        <div className="col-2 offset-1 d-none d-md-block text-md-end">
                            <img
                                src="/images/understanding-ats/ats-keywords.png"
                                title="ATS Matches Keywords"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-12 col-md-9 col-xl-7">
                            <h2 className="h3">
                                ATS Matches Keywords
                            </h2>
                            <p>
                                Applicant tracking systems match keywords within your resume to those within the job listing you're applying for.
                            </p>
                            <p>
                                Hard skills and soft skills can play a big part in inclusion by ATS. Experience and education with details matching the job listing will help as well.
                            </p>
                            <p className="mb-0">
                                Check the job listing you're applying for and make sure your resume contains a reasonable number of matching keywords!
                            </p>
                        </div>
                    </div>
                </section>
                <section className="container-fluid py-5 bg-light">
                    <div className="row">
                        <div className="col-12 offset-md-1 offset-lg-2 col-md-8 col-xl-6">
                            <h2 className="h3">
                                Resume Length <em>Does Not</em> Matter to ATS
                            </h2>
                            <p>
                                While a resume should be easy for a human to read, its length <em>does not</em> matter to ATS!
                            </p>
                            <p>
                                You still want to make sure your resume is short enough a potential employer can quickly get the information they need;
                                however, your first hurdle may be getting through ATS. Add as much information as you need to make sure your resume
                                passes ATS filtering and lands in your potential employers' hands.
                            </p>
                            <p className="mb-0">
                                This is a bit of a balancing act as you need to include enough information to pass ATS filtering but also have a resume
                                whose length is easy for a potential employer to quickly review.
                            </p>
                        </div>
                        <div className="col-2 col-xl-3 d-none d-md-block">
                            <img
                                src="/images/understanding-ats/ats-length.png"
                                title="ATS Ignores Page Count"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>
                <section className="container-fluid py-5">
                    <div className="row">
                        <div className="col-2 offset-1 d-none d-md-block text-md-end">
                            <img
                                src="/images/understanding-ats/ats-layout.png"
                                title="Page Layout Matters to ATS"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-12 col-md-9 col-xl-7">
                            <h2 className="h3">
                                Resume Layout Is Very Important to ATS
                            </h2>
                            <p>
                                A resume needs to look good; this is your first impression with a potential employer.
                                While we can use tools like Microsoft® Word and Google Docs to build aesthetically pleasing resumes,
                                these tools create complex document structures that can be difficult for ATS to read.
                            </p>
                            <p>
                                When ATS parses a resume, tables, text overlays, images, and other elements are removed, leaving just the resume text.
                                This may leave your resume text in an unrecognizable format, with headers, dates, and other elements no longer aligned where you'd placed them.
                            </p>
                            <p>
                                Avoid using tables to layout your resume. Headers, dates, and other items placed in differing rows and columns easily get parsed out of order by ATS.
                            </p>
                            <p className="mb-0">
                                When using images, make sure any important information (such as your contact information or school name) is also written out in text so that it can be parsed by ATS.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="container-fluid py-5 bg-light">
                    <div className="row">
                        <div className="col-12 offset-md-1 offset-lg-2 col-md-8 col-xl-6">
                            <h2 className="h3">
                                Why Do Companies Use ATS?
                            </h2>
                            <p>
                                You may wonder why companies use ATS if the majority of applicants get automatically screened out.
                            </p>
                            <p>
                                The short answer: it saves companies a <em>lot</em> of money.
                            </p>
                            <p className="mb-0">
                                Without ATS human resources or hiring managers would have to manually review and filter out every applicant.
                                For larger companies this already takes an enormous amount of time and human resources.
                                Having ATS filter candidates saves a lot of time and therefore money by only bringing in candidates who appear to meet the job listing criteria.
                            </p>
                        </div>
                        <div className="col-2 col-xl-3 d-none d-md-block">
                            <img
                                src="/images/understanding-ats/ats-reason.png"
                                title="Why Do Companies Use ATS?"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>
                <section className="container-fluid py-5">
                    <div className="row">
                        <div className="col-2 offset-1 d-none d-md-block text-md-end">
                            <img
                                src="/images/understanding-ats/ats-success.png"
                                title="How to Get a Resume Through ATS"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-12 col-md-9 col-xl-7">
                            <h2 className="h3">
                                How to Get a Resume Through ATS
                            </h2>
                            <p>
                                Applicant tracking systems are just software programs with predefined algorithms, yet no two ATS applications function exactly the same.
                            </p>
                            <p>
                                You may find tricks online to get your resume through ATS, however, these tricks are usually easily noticed by the person who receives your resume afterwards.
                                Stuffing your resume with too many keywords or using other tricks to get through ATS will likely be noticed by the hiring agent who may reject your application anyways.
                            </p>
                            <p className="mb-0">
                                The best way to get your resume through ATS is to build a good resume and make minor adjustments to tailor it to each job you apply for.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AtsHelp;
