import { FunctionComponent } from "react";
import "./Education.scss";

export interface EducationProps { }

export const Education: FunctionComponent<EducationProps> = (props) => {
    return (
        <div className="rb-education-component">
            Education
        </div>
    );
}

export default Education;
