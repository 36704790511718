import { FunctionComponent } from "react";
import "./GetStarted.scss";

export interface GetStartedProps { }

export const GetStarted: FunctionComponent<GetStartedProps> = (props) => {
    return (
        <div className="rb-get-started-component">
            <h2>
                Let's Get Started
            </h2>
            <p>
                We'll walk you through a few quick questions to get the information we need to build you the best resume possible.
            </p>
            <p>
                You're resume will be ATS friendly giving you an advantage over other applicants.
            </p>
        </div>
    );
}

export default GetStarted;
