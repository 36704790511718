import { JSXElementConstructor, ReactElement } from "react";
import { OrderType, PageInfo } from "../../models";
import { useNavigate } from "../../utilities";
import Footer from "./Footer/Footer";
import "./Layout.scss";
import Navigation from "./Navigation/Navigation";
import Tracking from "./Tracking/Tracking";

/** Configuration options for usePageLayout. */
export interface PageLayoutOptions {

    /** Information about the current page. */
    pageInfo: PageInfo;

    /** An optional flag which when true disables the default padding in the layout's body. */
    noPadding?: boolean;

    /** Hides the Get Started button. */
    hideLinks?: boolean;

    /** Use the primary blue for the header? */
    useDarkTheme?: boolean;

    /** Order type for checkout pages. */
    orderType?: OrderType;
}

/** A HOC which renders the page layout around any component. */
export const usePageLayout = (Component: JSXElementConstructor<any>, options: PageLayoutOptions): ReactElement => {
    const Layout: React.FunctionComponent = (props) => {
        const navigate = useNavigate("Layout");

        return (
            <div className="layout-component d-flex flex-column min-vh-100">
                <Tracking
                    pageInfo={options.pageInfo}
                />
                <header>
                    <Navigation 
                        hideLinks={!!options.hideLinks}
                        useDarkTheme={!!options.useDarkTheme}
                    />
                </header>
                <main role="main">
                    <div className={`container-fluid ${options.noPadding ? "px-0" : ""}`}>
                        <div className="row m-0 h-100">
                            <div className={`col ${options.noPadding ? "px-0" : "pt-3 px-2 px-sm-4"}`}>
                                <Component
                                    orderType={options.orderType}
                                 />
                            </div>
                        </div>
                    </div>
                </main>
                <footer className="mt-auto bg-gray border-top footer">
                    <Footer />
                </footer>
            </div>
        );
    }

    return (<Layout />);
};

export default usePageLayout;
